import {isKfcApp } from "@/utils/common";
import {saveLogger} from "@/assets/utils/request";


/**
 * app获取当前经纬度方法
 *@description: app => js传递数据
*/
export const getAppLatLng =  async () => {
  if (isKfcApp()) {
    try{
    return new Promise((resolve) => {
    window.addEventListener(
        'message',
        function handler (event) {
          try{
          let data = JSON.parse(event.data)
          if(data.data==null&&data.status&&data.status==-1){
            window.removeEventListener('message',handler)
            return resolve(-1)
          }else if (data.callbackName === 'appResponse') {
            window.removeEventListener('message',handler)
            return resolve(data.data||{})
          }
          }catch(err){
            saveLogger({
              message: '获取app数据失败_经纬度_getAppLatLng：'+err,
              data:event||''
            }, 'error')
          }
        },
        {once: true,capture: true}
      )
      const request = { service: 'getLatLng' }
      if(window.ReactNativeWebView){
       window.ReactNativeWebView.postMessage(JSON.stringify(request))
      }else{
        saveLogger({
          message: '获取app数据失败_不支持ReactNativeWebView方法：',
        }, 'error')
      }
    })
  }catch(err){
    saveLogger({
      message: '获取app数据失败_经纬度2：'+err,
    }, 'error')
    return new Promise((resolve) => {
      resolve(null)
    })
  }
  } else {
    console.log('当前环境为非app')
    return new Promise((resolve) => {
      resolve(null)
    })
  }
}
// 获取模糊定位地址
export const getLastLocation =  async () => {
  if (isKfcApp()) {
    try{
    return new Promise((resolve) => {
      window.addEventListener(
        'message',
        function getLastLocationHand (event) {
          try{
            let data = JSON.parse(event.data)
            console.log('模糊定位质地',data)
            if(data.data==null&&data.status&&data.status==-1){
              window.removeEventListener('message',getLastLocationHand)
              return resolve(-1)
            }else if (data.callbackName === 'appResponse') {
              window.removeEventListener('message',getLastLocationHand)
              return resolve(data.data||{})
            }
          }catch(err){
            saveLogger({
              message: '获取app数据失败_经纬度_getLastLocation：'+err,
              data:event||''
            }, 'info')
           
          }
        },
        {once: true,capture: true}
      )
      const request = { service: 'getLastLocation' }
      if(window.ReactNativeWebView){
        try{
          window.ReactNativeWebView.postMessage(JSON.stringify(request))
        }catch(err){
          return resolve(-1);
        }
      }else{
        saveLogger({
          message: '获取app数据失败_不支持ReactNativeWebView方法：',
        }, 'error')
        return resolve(-1);
      }
    })
  }catch(err){
    saveLogger({
      message: '获取app数据失败_经纬度2：'+err,
    }, 'error')
    return new Promise((resolve) => {
      resolve(null)
    })
  }
  } else {
    console.log('当前环境为非app')
    return new Promise((resolve) => {
      resolve(null)
    })
  }
}

// 首页显示的定位地址
export const getSelectLocation =  async () => {
  if (isKfcApp()) {
    try{
    return new Promise((resolve) => {
      window.addEventListener(
        'message',
        function getSelectLocationHand (event) {
          try{
            let data = JSON.parse(event.data)
            console.log('首页定位地址',data)
            if(data.data==null&&data.status&&data.status==-1){
              window.removeEventListener('message',getSelectLocationHand)
              return resolve(-1)
            }else if (data.callbackName === 'appResponse') {
              window.removeEventListener('message',getSelectLocationHand)
              return resolve(data.data||{})
            }
          }catch(err){
            saveLogger({
              message: '获取app数据失败_经纬度_getSelectLocation：'+err,
              data:event||''
            }, 'info')
           
          }
        },
        {once: true,capture: true}
      )
      const request = { service: 'getSelectLocation' }
      if(window.ReactNativeWebView){
        try{
          window.ReactNativeWebView.postMessage(JSON.stringify(request))
        }catch(err){
          return resolve(-1);
        }
      }else{
        saveLogger({
          message: '获取app数据失败_不支持ReactNativeWebView方法：',
        }, 'error')
        return resolve(-1);
      }
    })
  }catch(err){
    saveLogger({
      message: '获取app数据失败_经纬度2：'+err,
    }, 'error')
    return new Promise((resolve) => {
      resolve(null)
    })
  }
  } else {
    console.log('当前环境为非app')
    return new Promise((resolve) => {
      resolve(null)
    })
  }
}

export const getlatlngCallBack = (data)=>{
  console.log(data,'当前经纬度方法回调结果')
}

/**
 * app获取当前明白啦是否隐藏
 *@description: app => js传递数据
*/
export const getCouponTipsIsHide =  async () => {
  if (isKfcApp()) {
    console.log('getCouponTipsIsHide进入')
    try{
    return new Promise((resolve) => {
      window.addEventListener(
        'message',
        function getCouponTipsIsHideHand(event) {
            console.log('getCouponTipsIsHide结果响应')
            console.log('getCouponTipsIsHide',event)
          try {
            let appData = JSON.parse(event.data)
            if (appData.callbackName === 'appResponse') {
              window.removeEventListener('message',getCouponTipsIsHideHand)
              return resolve(appData.data||'')
            }
          } catch (error) {
            console.log('🚀 ~ getCouponTipsIsHideHand ~ error:', error, event.data)
            return resolve('')
          }
        },
        true
      )
      const request = { service: 'getCouponTipsIsHide' }
      window.ReactNativeWebView.postMessage(JSON.stringify(request))
      console.log('getCouponTipsIsHide触发')

    })
  }catch(err){
    console.log('getCouponTipsIsHide错误输出',err)
    console.error(err)
    return new Promise((resolve) => {
      resolve(null)
    })
  }
  } else {
    console.log('当前环境为非app')
    return new Promise((resolve) => {
      resolve(null)
    })
  }
}

/**
 * app获取进入当前页面参数
 *@description: app => js传递数据
*/
export const getAppParams =  async () => {
  if (isKfcApp()) {
    try{
      return new Promise((resolve) => {
        window.addEventListener(
          'message',
          function getAppParamsHand(event) {
            try{
            console.log('getAppParams响应',event)
            let appData=JSON.parse(decodeURIComponent(event.data)||"{}")
            console.log(appData,'app参数')
            if (appData.callbackName === 'appResponse') {
              window.removeEventListener('message',getAppParamsHand)
              return resolve(appData.data||{})
            }else{
              window.removeEventListener('message',getAppParamsHand)
              return resolve(null)
            }
           
          }catch(err){
            saveLogger({
              message: '获取app数据失败_app参数获取1：'+err,
              data:event||''
            }, 'error')
            resolve(null)
          }
          },
          {once: true,capture: true}
        )
        const request = { service: 'getAppParams' }
        if(window.ReactNativeWebView){
          window.ReactNativeWebView.postMessage(JSON.stringify(request))
        }else{
          saveLogger({
            message: '获取app数据失败_不支持ReactNativeWebView方法：',
          }, 'error')
          resolve(null)
          // 订单详情模拟数据
          // return resolve({"recordId":"B3E1EB82-2C07-4764-B201-202401181808","orderType":"3","positionInfo":{"brand":"KFCHK","addressCode":"254AEA80-059B-409C-85C7-202401181810","contactPerson":"Jfjjfjf","titlesId":7,"titlesName":"先生","titlesCode":"U01","titleId":7,"titleName":"先生","titleCode":"U01","areaCode":"852","phoneNo":"12355851","email":"85212355851@storellet.com","cityId":3,"cityName":"香港島","cityCode":"C01","district":"YAU TSIM MONG DISTRICT","street":"","building":"MONGKOK BUILDING","block":"","floor":"","flat":"","isDefault":true,"lat":22.32097,"lng":114.17114,"remarks":"测试","addressRemarks":"test","fullAddress":"MONGKOK BUILDING, 95, MONG KOK ROAD, YAU TSIM MONG DISTRICT, KLN","address":null,"latitude":22.32097,"longitude":114.17114},"app":true,"brand":"KFCHK","language":1,"deviceId":"fdb0c6b14a19a745","KFCRemember":false,"accecode":"/m+ek2ZhbLq1WMn7fe+o8k3llS4XTkKXTxDZ23ByiqE2oHlxplQr42j/ss/05j1nXE63+oQH2logoormxix9JuFqKxMF2qb1iH1dIKjLNu3e26vJJJP/FFwOYMvUxHnySfeoXdCCFUHnkmUzRlXrGA==","userId":"160306838","phone":"12355851"});
          // 餐单模拟数据
          // return resolve({"app":true,"brand":"KFCHK","language":1,"deviceId":"fdb0c6b14a19a745","KFCRemember":false,"accecode":"/m+ek2ZhbLq1WMn7fe+o8k3llS4XTkKXTxDZ23ByiqE2oHlxplQr42j/ss/05j1nXE63+oQH2logoormxix9JuFqKxMF2qb1iH1dIKjLNu3e26vJJJP/FFwOYMvUxHnySfeoXdCCFUHnkmUzRlXrGA==","userId":"160306838","phone":"12355851","sendType":"1","storeid":"","positionInfo":{"brand":"KFCHK","addressCode":"674AC2B4-CED1-451D-9C96-202311011039","contactPerson":"test","titlesId":8,"titlesName":"女士","titlesCode":"U02","titleId":8,"titleName":"女士","titleCode":"U02","areaCode":"852","phoneNo":"12355851","email":"gghjjj@can-dao.com","cityId":3,"cityName":"香港島","cityCode":"C01","district":"帝庭轩","street":"","building":"一期二座","block":"1","floor":"1","flat":"f","tagId":11,"tagName":"其他","tagCode":"T03","isDefault":false,"lat":22.50073,"lng":114.14422,"remarks":"","fullAddress":"粉嶺 和滿街 8號 帝庭軒 3座","address":null,"latitude":22.50073,"longitude":114.14422}})
        }
        
      })
    }catch(err){
      console.error(err)
      saveLogger({
        message: '获取app数据失败_app参数获取2：'+err,
      }, 'error')
      return new Promise((resolve) => {
        resolve(null)
      })
    }
  } else {
    console.log('当前环境为非app')
    return new Promise((resolve) => {
      resolve(null)
    })
  }
}

/**
 * app获取app登录页记住我数据
 *@description: app => js传递数据
*/
export const getloginRemember =  async () => {
  if (isKfcApp()) {
    try{
    return new Promise((resolve) => {
      window.addEventListener(
        'message',
        function (event) {
          let appData = JSON.parse(event.data)
          if (appData.callbackName === 'appResponse') {
            return resolve(appData.data||'')
          }
          setTimeout(()=>{
            window.removeEventListener('message')
          })
        },
        {once: true,capture: true}
      )
      const request = { service: 'getloginRemember' }
      window.ReactNativeWebView.postMessage(JSON.stringify(request))
    })
  }catch(err){
    console.error(err)
    return new Promise((resolve) => {
      resolve(null)
    })
  }
  } else {
    console.log('当前环境为非app')
    return new Promise((resolve) => {
      resolve(null)
    })
  }
}
export const onScanr =  async () => {
  if (isKfcApp()) {
    try{
    return new Promise((resolve) => {
    window.addEventListener(
        'message',
        function getScan (event) {
          try{
            let data = JSON.parse(event.data)
              console.log('扫码结果',data)
            // window.removeEventListener('message',getScan)
            if(data.data==null&&data.status&&data.status==-1){
              window.removeEventListener('message',getScan)
              return resolve(null)
            }else if (data.callbackName === 'appResponse') {
              window.removeEventListener('message',getScan)
              return resolve(data?.data?.scanrResult||null)
            }
          }catch(err){
            saveLogger({
              message: '获取app数据失败_扫码3：'+err,
              data:event||''
            }, 'error')
          }
        },
        true
      )
      const request = { service: 'onScanr' }
      if(window.ReactNativeWebView){
       window.ReactNativeWebView.postMessage(JSON.stringify(request))
      }else{
        saveLogger({
          message: '获取app数据失败_扫码1：',
        }, 'error')
      }
    })
  }catch(err){
    saveLogger({
      message: '获取app数据失败_扫码2：'+err,
    }, 'error')
    return new Promise((resolve) => {
      resolve(null)
    })
  }
  } else {
    console.log('当前环境为非app')
    return new Promise((resolve) => {
      resolve(null)
    })
  }

}

/**
  *@description: js => app传递数据
  * @param {string} service 触发事件，例如：logout-退出登录 login-登录 setAddress-更改地址信息 loginRemember-登录页记住我数据
  *@param {object} params 传递参数
*/
export const postDataToApp = (service,params={}) => {
  // 判断是非app环境
  if(isKfcApp()){
    // 传递事件和数据到APP端
    try{
      const request = { service: service, data: params }
      window.ReactNativeWebView.postMessage(JSON.stringify(request))
    }catch(err){
      console.error(err)
    }
  

  }else{
    // 非app环境不执行
    console.log('当前环境为非app')
  }
 
}

