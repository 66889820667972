/*
 * @Date: 2023-10-9 10:43:00
 * @Description: 获取游客模式优惠券方法处理
 * @FilePath: JRG-KFC\src\utils\common.js
 */
import {postPaas} from "@/assets/utils/request";
import store from './../store'
import {getTimestampByDate} from "./time-zone";


/**
 * @description: 游客模式获取优惠券
 * @param {}
 */
export const voucherWelcomePackGet = async () =>{
  const params = {
    actionName: 'candao.member.voucherWelcomePackGet',
    content: {}
  }
  // 如果为yuu,则无需调用接口,yuu不显示kfc券
  if(store.state.isYuu){
    return {status:1,data:{data:[]}};
  }
  let result = await postPaas('UserUnify', params)
  return result;
}

/**
 * @description: 更新用户邮箱信息
 * @param {}
 */
 export const userUpdateEmail =  (data={}) =>{
  let userInfo = store.state.userInfo;
  // 有邮箱时不更新
  if(userInfo.emial){
    return false
  }
  // 修改用户信息
  let params = {
    actionName: "candao.member.userUpdate",
    content: {
      genderId: userInfo.genderId,
      birthday: {
        year: userInfo.birthday.month,
        month: userInfo.birthday.month,
        day: userInfo.birthday.day,
      },
      firstName: userInfo.firstName || userInfo.lastName || 'Member',
      receiveNews: true,
      addressNotification: userInfo.addressNotification || false,
      ...data
    },
  };
  try{
    postPaas("UserUnify", params);
  }catch(err){
    console.error(err)
  }
}
export const  isTimeInRange = (timeRanges) => {
  const now = new Date();
  for (const range of timeRanges) {
    const [startHour, startMinute] = range.startTime.split(':').map(Number);
    const [endHour, endMinute] = range.endTime.split(':').map(Number);

    const startTime = new Date();
    startTime.setHours(startHour, startMinute, 0, 0);

    const endTime = new Date();
    endTime.setHours(endHour, endMinute, 0, 0);

    if (now >= startTime && now <= endTime) {
      return true;
    }
  }

  return false;
}
// 判断特别活动日期
export const specialDatesCheck = (specialDates,serverTime)=>{
  let isNotBusiness = false // 判断是非不在营业时间范围内
  const nowTime = serverTime;
  specialDates.forEach(item=>{
    let startDate = item?.date?.startDate||''
    let endDate = item?.date?.endDate||''
    if(startDate&&endDate){
      let startDate_ =  getTimestampByDate(startDate + 'T00:00:00')
      let endDate_ =  getTimestampByDate(endDate + 'T23:59:59')
      if (nowTime >= startDate_ && nowTime <= endDate_) {
        if(item?.times?.length){
          let isOk = isTimeInRange(item.times)
          isNotBusiness = !isOk
        }else{
          isNotBusiness = true
        }
      }
    }
  })
  return isNotBusiness
}





